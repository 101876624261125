import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-see-also',
  templateUrl: './see-also.component.html',
  styleUrls: ['./see-also.component.scss']
})
export class SeeAlsoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
