import { Component, OnInit } from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { CookieMaterialComponent } from '../cookie-material/cookie-material.component';
import * as $ from 'jquery';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear = new Date().getFullYear();

  constructor(private _bottomSheet: MatBottomSheet) {}

  ngOnInit() {
    //this.openBottomSheet();
    // $(window).click(function () {
    //   alert('ok');
    //   });
  }

  // openBottomSheet(): void {
  //   this._bottomSheet.open(CookieMaterialComponent, {panelClass: 'myapp-no-padding-dialog'});
  // }

}
