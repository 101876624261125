import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bar-tmp',
  templateUrl: './bar-tmp.component.html',
  styleUrls: ['./bar-tmp.component.scss']
})
export class BarTmpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
