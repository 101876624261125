import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { BanerComponent } from './baner/baner.component';
import { ZobaczComponent } from './zobacz/zobacz.component';
import { BiuraRachunkoweComponent } from './biura-rachunkowe/biura-rachunkowe.component';
import { KosztujeComponent } from './kosztuje/kosztuje.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { CookieMaterialComponent } from './cookie-material/cookie-material.component';
import { BarTmpComponent } from './bar-tmp/bar-tmp.component';
import { TutorialeComponent } from './tutoriale/tutoriale.component';
import { ZasadyLicencjonowaniaComponent } from './zasady-licencjonowania/zasady-licencjonowania.component';
import { SeeAlsoComponent } from './see-also/see-also.component';
import { PopupPortalDokumentowComponent } from './popup-portal-dokumentow/popup-portal-dokumentow.component';
import { AlertComponent } from './alert/alert.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BanerComponent,
    ZobaczComponent,
    BiuraRachunkoweComponent,
    KosztujeComponent,
    FooterComponent,
    CookieMaterialComponent,
    BarTmpComponent,
    TutorialeComponent,
    ZasadyLicencjonowaniaComponent,
    SeeAlsoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatBottomSheetModule
  ],
  entryComponents:[
    CookieMaterialComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
